<template>
<div>
   <el-breadcrumb class="breadcrumb-title" separator="/">
            <el-breadcrumb-item>企业设置</el-breadcrumb-item>
            <el-breadcrumb-item>操作日志</el-breadcrumb-item>
        </el-breadcrumb>
  <el-card>
    <div class="Journal-title">操作日志</div>
    <el-form class="from-label flex-row" ref="form" label-width="80px">
      <el-form-item label="操作人 :">
        <SearchInput
          class="search-input"
          placeholderStr="请输入姓名"
          :resetFlag="resetFlag"
          @updateSearchList="updateSearchList"
        />
      </el-form-item>
      <el-form-item label="操作时间 :" ref="dateValue">
      
        <el-date-picker
            v-model="dateValue"
            type="datetimerange"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            :picker-options="pickerOptions"
          >
          </el-date-picker>
  


      </el-form-item>
    </el-form>
    <el-form
      class="from-label from-bottom"
      ref="form"
      :model="searchMes"
      label-width="80px"
      style="margin-left:19px"
    >
      <el-button class="el-button" @click="reset">重 置</el-button>
      <el-button type="primary" class="buttonColor" @click="getExportData">导 出</el-button>
    </el-form>
    <el-table
      :data="tableData"
      border
      style="width: 100%"
      :header-cell-style="{ background: '#F5F7FA', color: '#606266' }"
    >
      <el-table-column  prop="id" label="编号" width="180"></el-table-column>
    <el-table-column
       show-overflow-tooltip
        prop="content"
        label="操作内容"
        style=" width:180px"
        width="180"
      ></el-table-column>
      <el-table-column   prop="user_name" label="操作人"></el-table-column>
      <el-table-column prop="created_at" label="操作时间"></el-table-column>
      <el-table-column prop="ip" label="操作IP"></el-table-column>
      <el-table-column prop="ip_addr" label="IP归属地"></el-table-column>
    </el-table>
  <div class="page_card" v-if="count > 0">
    <el-pagination
      @current-change="handleCurrentChange"
      :current-page="curPage"
      :page-size="pageSize"
      :page-sizes="[pageSize]"
      layout="prev, pager, next, sizes, jumper , total"
      background
      :total="count"
    >
    </el-pagination>
  </div>
    </el-card>

   <Table
      v-show="false"
      ref="exportTable"
      :columns="tableTitle"
      :data="exportData"
    ></Table>
  </div>
</template>
<script>
import request from "@request/api/index";
import SearchInput from "@components/SearchInput";
import moment from "dayjs";
import {debounce} from "@utils/utils";

Date.prototype.Format = function (fmt) { // author: meizz
  var o = {
    "M+": this.getMonth() + 1, // 月份
    "d+": this.getDate(), // 日
    "h+": this.getHours(), // 小时
    "m+": this.getMinutes(), // 分
    "s+": this.getSeconds(), // 秒
    "q+": Math.floor((this.getMonth() + 3) / 3), // 季度
    "S": this.getMilliseconds() // 毫秒
  };
  if (/(y+)/.test(fmt))
    fmt = fmt.replace(RegExp.$1, (this.getFullYear() + "").substr(4 - RegExp.$1.length));
  for (var k in o)
    if (new RegExp("(" + k + ")").test(fmt)) fmt = fmt.replace(RegExp.$1, (RegExp.$1.length == 1) ? (o[k]) : (("00" + o[k]).substr(("" + o[k]).length)));
  return fmt;
}

export default {
  components: {
    SearchInput,
  },
  data() {
    return {
      resetFlag: 0,//重置表单组件信号码
      isSearch: true,//是否进行搜索
      input: "",
      curPage: 1,
      pageSize: 10,
      list: [],
      dateValue: "",
      page: 1,
      tableData: [],
      searchDate: [],
      searchMes: {},
      curList: [],
      pageDate: [],
      exportData:[],
      searchInputText: "",
      startDate: "",
      endDate: "",
      eptDate:[],
       count:0,
        tableTitle: [

        {
          title: "编号",
          key: "id",
        },

        {
          title: "操作内容",
          key: "content",
        },

        {
          title: "操作人",
          key: "user_name",
        },
        {
          title: "操作时间",
          key: "created_at",
        },
        {
          title: "操作IP",
          key: "ip",
        },
        {
          title: "IP归属地",
          key: "ip_addr",
        },
      ],
      pickerOptions: {
        disabledDate: (time) => {
          let curDate = new Date().getTime();
          let three = 30 * 24 * 3600 * 1000;
          let threeMonths = curDate - three;
          return time.getTime() > Date.now() || time.getTime() < threeMonths;
        },
      },
    };
  },
  created() {
    this.getJournal();
    this.$watch('searchInputText', debounce((newSearchText) => {
      this.curPage = 1
      this.getJournal(this.startDate, this.endDate, this.searchInputText + '')
    }, 200))
  },
  watch: {
    dateValue(cur) {
      if (this.dateValue) {
        let startDate = this.getFormatDate(this.dateValue[0].getTime());
        // this.searchInputText = startDate;
        this.startDate = this.dateValue[0].Format("yyyy-MM-dd hh:mm:ss");
        this.endDate = this.dateValue[1].Format("yyyy-MM-dd hh:mm:ss");
        console.log(this.startDate)
        console.log(this.endDate)
        // this.filterData1();
        this.curPage = 1
        this.getJournal(this.startDate, this.endDate, this.searchInputText + '')
      } else {
        this.startDate = ""
      }
    },
    pageDate(cur) {
      this.computeList();
    },
  },
  methods: {
    computeList() {
      // let start = (this.curPage - 1) * this.pageSize;
      // let end = start + this.pageSize;
      this.tableData = JSON.parse(JSON.stringify(this.pageDate))
    },
    
    getFormatDate(time) {
      let day = new Date(time);
      console.log(day)
      return (
        day.getFullYear() +
        "-" +
        (day.getMonth() > 9 ? day.getMonth() + 1 : "0" + (day.getMonth() + 1)) +
        "-" +
        (day.getDate() > 9 ? day.getDate() : "0" + day.getDate())
      );
    },
    async getJournal(start_date, end_date, name) {
      let params = {
        export: 0,
        start_date: start_date ? start_date : '',
        end_date: end_date ? end_date : '',
        name: name ? name : '',
        page:this.curPage,
        page_size:10
      };
      await request.enterprise.requestJournal(params).then((res) => {
        res.data.data.list.forEach((element) => {
          element.created_at = moment(element.created_at * 1000).format(
            "YYYY-MM-DD H:m:s"
          );
        });
        this.tableData = res.data.data.list;
        this.searchDate = res.data.data.list;
        this.pageDate = res.data.data.list;
        this.count=res.data.data.count;
        this.eptDate = res.data.data.list;
      });
    },
    handleCurrentChange(val) {
      this.curPage = val;
      this.getJournal(this.startDate, this.endDate, this.searchInputText + '')
      // this.computeList();
    },
    search(type1, type2) {
      this.getJournal(type1, type2);
    },
    filterData() {
      if(!this.startDate && !this.searchInputText){
        // console.log(this.searchDate)
        this.pageDate = this.searchDate;
        this.isSearch = true;
        return
      }
      let copyList = JSON.parse(JSON.stringify(this.searchDate));
      let arr = [];
      for (let i = 0; i < copyList.length; i++) {
        const ci = copyList[i].created_at.indexOf(this.startDate);
        const ui = copyList[i].user_name.indexOf(this.searchInputText);
        if ((!this.searchInputText && ci >= 0) || (!this.startDate && ui >= 0) || (ui >= 0 && ci >= 0)) {
          arr.push(copyList[i]);
        }
        //  else {
        //   this.pageDate = this.searchDate;
        //   return false;
        // }
      }
      this.pageDate = arr;
      this.isSearch = true;
    },
    updateSearchList(str) {
      this.searchInputText = str;
      if(!this.isSearch) return;
      // this.filterData();
      console.log(str)
    },
    reset() {
      this.resetFlag++;
      this.isSearch = false;
      this.dateValue = "";
      this.endDate = ''
      this.curPage = 1
      this.$nextTick(()=>{

      })
      this.getJournal(this.startDate, this.endDate, this.searchInputText + '')
            // this.$refs.dateValue.clearFiles();
      // this.filterData();
    },
   
    // 导出
    getExportData (n) {
        if (this.pageDate.length == 0) {
          this.$message({
            type: "error",
            message: "当前页数据为空",
            center: true
          });
          return;
        }
        request.enterprise.requestJournal({
          export:1,
          start_date: this.startDate ? this.startDate : '',
          end_date: this.endDate ? this.endDate : '',
          name: this.searchInputText ?  this.searchInputText : ''
          }).then((res)=>{
            res.data.data.list.forEach((element) => {
              element.created_at = moment(element.created_at * 1000).format(
                "YYYY-MM-DD H:m:s"
              );
            });
          this.$refs.exportTable.exportCsv({
            quoted: true,
            filename: new Date().getTime() + '',
            columns: this.tableTitle.filter((col, index) => index < 9),
            data: res.data.data.list
          });
          
        });

    },
  },
};
</script>
<style lang="scss" scoped>
@import "@/assets/style/page.scss";
.el-table {
  width: 97% !important;
  margin: 0 auto;
  border-radius: 8px;
}
.Journal-title {
  height: 22px;
  font-size: 16px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: rgba(0, 0, 0, 0.85);
  line-height: 22px;
}
.el-table thead {
  color: rgba(0, 0, 0, 0.85);
}
.search-input {
  width: 176px !important;
}
.box-search {
  display: flex;
  justify-content: space-between;
}
.addBtn {
  height: 36px;
  background: #3468fe;
  border-radius: 6px;
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #ffffff;
}
.el-form-item {
  width: 300px !important;
}
.from-bottom {
  margin-bottom: 16px;
}
.el-button--primary.is-plain{
  border-color: #ffffff;
}
.el-card{
  position: relative;
  min-height: 900px;
}
</style>
